/**
 * Page to deisplay student payment records
 */
import {Box, Grid, Link, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Currency, Message, SectionHeading, useGlobal} from "up-form";
import {usePayments} from "up-state";
import {selectUser} from "../../state/appReducers";
import Recent from "../icons/Recent";
import SimpleTable from "../table/SimpleTable";

const useStyles = makeStyles((theme) => ({
  type: {
    fontWeight: "bold"
  },
  account: {},
  amount: {},
  invoiceNumber: {
    textAlign: "right"
  },
  summaryAmount: {
    fontSize: "2rem",
    fontWeight: "500"
  },
  debit: {
    color: "red"
  },
  smallFraction: {
    fontSize: "smaller"
  },
  credit: {
    color: "green"
  },
  date: {
    fontWeight: "light"
  }
}));

const PaymentsPage = () => {
  const {studentId} = useSelector(selectUser) || {};

  const {data: {invoices, payments} = {}, error} = usePayments(studentId),
    {t} = useTranslation(),
    {
      component: {
        PaymentsPage: {pageSize = 10, recentPeriod = "PT24H"}
      },
      links: {
        debitSuccess: {link, phone, email}
      }
    } = useGlobal(),
    classes = useStyles(),
    now = moment(),
    recent = now.clone().subtract(moment.duration(recentPeriod));

  function signedAmount({paymentType, amount}) {
    return ["ACCRECPAYMENT", "ACCPAYPAYMENT"].indexOf(paymentType) >= 0 ? amount : -amount;
  }

  const paymentDetailsById = payments && payments.reduce((acc, v) => ({[v.paymentID]: v, ...acc}), {});

  const PaymentCard = ({date, paymentType, account: {code} = {}}) => {
    const transactionDate = date && moment.utc(date),
      isRecent = transactionDate && transactionDate.isBefore(now) && transactionDate.isAfter(recent);
    return (
      <Grid container direction="column">
        <Grid item>
          {isRecent && <Recent />}
          {paymentType && (
            <Typography display="inline" variant="subtitle1" className={classes.type}>
              {t(`PaymentsPage.payment.type.${paymentType}`)}
            </Typography>
          )}
        </Grid>
        {code && (
          <Typography display="inline" variant="subtitle2" className={classes.code}>
            {t(`PaymentsPage.payment.accountCode.${code}`)}
          </Typography>
        )}
        {transactionDate && (
          <Typography component="span" variant="subtitle2" className={classes.date}>
            {t("PaymentsPage.payment.date", {
              date: transactionDate.format("Do MMMM, YYYY")
            })}
          </Typography>
        )}
      </Grid>
    );
  };

  const Invoice = ({invoiceNumber, payments, amountDue, amountPaid}) => (
    <Box padding={1}>
      <SectionHeading title={t("PaymentsPage.invoice.title", {invoiceNumber})} />
      <Typography gutterBottom variant="subtitle2" className={classes.invoiceNumber}>
        {t("PaymentsPage.invoice.summary.invoiceNumber", {invoiceNumber})}
      </Typography>
      <Grid direction="row" container spacing={2} padding={1} alignItems="flex-start">
        <Grid item>
          <Typography variant="subtitle1">{t("PaymentsPage.invoice.summary.paid")}</Typography>
          <Currency
            value={amountPaid}
            classes={{
              root: classes.summaryAmount,
              plusSign: classes.credit,
              fraction: classes.smallFraction
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{t("PaymentsPage.invoice.summary.due")}</Typography>
          <Currency
            value={-amountDue}
            classes={{
              root: classes.summaryAmount,
              minusSign: classes.debit,
              fraction: classes.smallFraction
            }}
          />
        </Grid>
      </Grid>
      <SimpleTable
        itemName="Transaction"
        emptyLabel={t("PaymentsPage.noPayments")}
        rows={payments.map(({paymentID}) => paymentDetailsById[paymentID] || {})}
        renderRow={(payment) => [<PaymentCard {...payment} />, <Currency value={signedAmount(payment)} />]}
        pageSize={pageSize}
        getCellProps={(column) => ({align: column > 0 ? "right" : "left"})}
        sort={{
          Amount: (a, b) => signedAmount(a) - signedAmount(b),
          Date: ({date: a}, {date: b}) => moment(a || undefined) - moment(b || undefined)
        }}
        defaultSortColumn="Date"
      />
    </Box>
  );

  const actualError = error && error.status !== 404 && error; // The GET payments API erroneously returns 404 when there are no payments for a user, so don't treat that as an error

  return (
    <Box pt={1}>
      <Typography variant="h1">{t("PaymentsPage.title")}</Typography>
      <Typography paragraph variant="body1">
        {t("PaymentsPage.intro")}
      </Typography>
      {(Array.isArray(invoices) && invoices.length) > 0 ? (
        invoices.map((invoice, i) => <Invoice key={i} {...invoice} />)
      ) : (
        <Typography paragraph variant="body1">
          {t("PaymentsPage.error.noInvoices")}
        </Typography>
      )}
      <Typography paragraph variant="body1">
        <Trans
          t={t}
          i18nKey="PaymentsPage.footer"
          components={{
            contact: <Link href={link} />,
            phone: <Link href={`tel:${phone}`} />,
            email: <Link href={`mailto:${email}`} />
          }}
          values={{email, phone, contact: "Debit Success"}}
        />
      </Typography>
      {actualError && <Message variant="error" message={actualError.message} open={!!actualError} />}
    </Box>
  );
};

export default PaymentsPage;

/* Xero Payment (detailled)
            "PaymentID": "9426940d-e4ce-4269-9ada-815fffe166b1",
            "Date": "/Date(1612396800000+0000)/",
            "BankAmount": 100.00,
            "Amount": 100.00,
            "Reference": "00001200050986720c4f601120fa01d5",
            "CurrencyRate": 1.000000,
            "PaymentType": "ACCRECPAYMENT",
            "Status": "AUTHORISED",
            "UpdatedDateUTC": "/Date(1612411005520+0000)/",
            "HasAccount": true,
            "IsReconciled": false,
            "Account": {
                "AccountID": "e54a34a6-3fe6-4af2-b3de-edf5aed6e99d",
                "Code": "credcard"
            },
            "Invoice": {
                "Type": "ACCREC",
                "InvoiceID": "12594803-7d27-4c16-a8be-f93879b8a52a",
                "InvoiceNumber": "INV-08475-Z9Z9V7",
                "Payments": [],
                "CreditNotes": [],
                "Prepayments": [],
                "Overpayments": [],
                "IsDiscounted": false,
                "HasErrors": false,
                "Contact": {
                    "ContactID": "40ee8342-7704-405f-a944-66bf4cfce4bb",
                    "Name": "Frederick Lueilwitz_270039659",
                    "Addresses": [],
                    "Phones": [],
                    "ContactGroups": [],
                    "ContactPersons": [],
                    "HasValidationErrors": false
                },
                "LineItems": [],
                "CurrencyCode": "NZD"
            },
            "HasValidationErrors": false
      */
/*
        "Type": "ACCREC",
        "InvoiceID": "12594803-7d27-4c16-a8be-f93879b8a52a",
        "InvoiceNumber": "INV-08475-Z9Z9V7",
        "Reference": "",
        "Payments": [
            {
                "PaymentID": "9426940d-e4ce-4269-9ada-815fffe166b1",
                "Date": "/Date(1612396800000+0000)/",
                "Amount": 100.00,
                "Reference": "00001200050986720c4f601120fa01d5",
                "CurrencyRate": 1.000000,
                "HasAccount": false,
                "HasValidationErrors": false
            },
            {
                "PaymentID": "063b0244-7aab-4b7c-8f74-05d34fc4fb7c",
                "Date": "/Date(1612396800000+0000)/",
                "Amount": 99.00,
                "Reference": "1480707383",
                "CurrencyRate": 1.000000,
                "HasAccount": false,
                "HasValidationErrors": false
            }
        ],
        "Prepayments": [],
        "Overpayments": [],
        "AmountDue": 152.00,
        "AmountPaid": 199.00,
        "SentToContact": false,
        "CurrencyRate": 1.000000,
        "IsDiscounted": false,
        "HasAttachments": false,
        "HasErrors": false,
        "Attachments": [],
        "Contact": {
            "ContactID": "40ee8342-7704-405f-a944-66bf4cfce4bb",
            "AccountNumber": "270039659",
            "ContactStatus": "ACTIVE",
            "Name": "Frederick Lueilwitz_270039659",
            "FirstName": "Frederick",
            "LastName": "Lueilwitz_270039659",
            "EmailAddress": "Frederick19@hiko.co.nz",
            "BankAccountDetails": "",
            "Addresses": [
                {
                    "AddressType": "STREET",
                    "City": "",
                    "Region": "",
                    "PostalCode": "",
                    "Country": ""
                },
                {
                    "AddressType": "POBOX",
                    "City": "",
                    "Region": "",
                    "PostalCode": "",
                    "Country": ""
                }
            ],
            "Phones": [
                {
                    "PhoneType": "DEFAULT",
                    "PhoneNumber": "",
                    "PhoneAreaCode": "",
                    "PhoneCountryCode": ""
                },
                {
                    "PhoneType": "DDI",
                    "PhoneNumber": "",
                    "PhoneAreaCode": "",
                    "PhoneCountryCode": ""
                },
                {
                    "PhoneType": "FAX",
                    "PhoneNumber": "",
                    "PhoneAreaCode": "",
                    "PhoneCountryCode": ""
                },
                {
                    "PhoneType": "MOBILE",
                    "PhoneNumber": "",
                    "PhoneAreaCode": "",
                    "PhoneCountryCode": ""
                }
            ],
            "UpdatedDateUTC": "/Date(1612409543680+0000)/",
            "ContactGroups": [],
            "IsSupplier": false,
            "IsCustomer": true,
            "SalesTrackingCategories": [],
            "PurchasesTrackingCategories": [],
            "ContactPersons": [],
            "HasValidationErrors": false
        },
        "DateString": "2021-02-04T00:00:00",
        "Date": "/Date(1612396800000+0000)/",
        "DueDateString": "2021-02-11T00:00:00",
        "DueDate": "/Date(1613001600000+0000)/",
        "BrandingThemeID": "7889a0ac-262a-40e3-8a63-9a769b1a18af",
        "Status": "AUTHORISED",
        "LineAmountTypes": "Inclusive",
        "LineItems": [
            {
                "Description": "TEST2020 ONLINE NEW COURSE",
                "UnitAmount": 351.00,
                "TaxType": "OUTPUT2",
                "TaxAmount": 45.78,
                "LineAmount": 351.00,
                "AccountCode": "DEBSUC",
                "Tracking": [
                    {
                        "Name": "Provider",
                        "Option": "up-online",
                        "TrackingCategoryID": "8e624ecf-38db-4af5-b526-d475936fb5b8",
                        "TrackingOptionID": "e1d35c69-b8f0-4e78-ae41-525d443d9226"
                    }
                ],
                "Quantity": 1.0000,
                "LineItemID": "14a7c831-f30c-41e1-b2a2-7ba119b80dd0",
                "ValidationErrors": []
            }
        ],
        "SubTotal": 305.22,
        "TotalTax": 45.78,
        "Total": 351.00,
        "UpdatedDateUTC": "/Date(1612411512010+0000)/",
        "CurrencyCode": "NZD"
*/
